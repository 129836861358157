.btn-primary,.btn-success{
    margin-top: 0.5em;
    border-radius: 5px;
    color: white !important;
    background-color: #135e7e !important;
    border-color: #135e7e !important;
}

.btn-primary,.btn-success,.btn-info,.btn-danger {
    margin-top: 0.5em;
    border-radius: 5px;
}

.btn-primary:hover,.btn-success:hover {
    background-color: #a2d9f7 !important;
    border-color: #a2d9f7 !important;
    color: #fff !important;
}
h3,h4{color:#135e7e !important; font-weight:500; text-align: center;}
h2{color:#135e7e !important; font-weight:500;}

a {
    text-decoration: none !important;
    color: #135e7e !important;
    font-size: 0.98em;
}

 a:hover {
     color: black !important;
    text-decoration: none !important;
}

.h5 {
    font-weight: 500 !important;
    font-size: 1.2em !important;
}

body{
    font-family: 'Roboto', sans-serif;
}
