.pro-sidebar-layout{
    background: white;
    color: #3d5170;
    /*box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);*/
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: transparent;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: white;
}

.sidebarHeader
{
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.SidebarLogo{
    height: 60px;
}

.ta-center {
    text-align: center;
}

#sidebarCol {
    margin-top: 0px;
    height: calc(100vh - 0px);
}
