body {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}
.bg {
    font-family: sans-serif;
    background-color: wheat;
    height: auto;
    padding: 1.5rem;
}
.hight {
    height: 100vh;
    display: block;
}
.menu {
    background-color: white;
    padding: 15px;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}
.menu::-webkit-scrollbar {
    width: 10px;
}
.menu::-webkit-scrollbar-track {
    background-color: gainsboro;
}
.menu::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 200px;
}
.menuItem {
    padding: 8px;
    margin: 3px 0px;
    border: none;
}
