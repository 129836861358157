

.DC_Title {
    min-height:50px;
}

.DC_Text {
    height:110px;
}
.DC_Button {
    width:100%;
}

.DC_Card{
    width: 18rem;
}
